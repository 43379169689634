<script lang="ts">
  import type { MarketoForm } from '$lib/contentful/models/marketo-form';

  import MerchDiscountCodeSignup from './merch-discount-code-signup.svelte';
  import AskAnExpert from './ask-an-expert.svelte';
  import NewsletterSignup from './newsletter-signup.svelte';
  import GoogleAdsLandingPage from './google-ads-landing-page.svelte';
  import EventSignup from './event-signup.svelte';

  import DynamicForm from '../dynamic-marketo-form/marketo.svelte';
  import { getFormMetadata } from '../dynamic-marketo-form/get-form-metadata';

  const Forms = {
    AskAnExpert: 1450,
    NewsletterSignup: 1001,
    MerchDiscountCodeSignup: 1451,
    GoogleAdsLandingPage: 1508,
    EventSignup: 1612,
  } as const;

  export let content: MarketoForm;
  $: ({ formId, successMessage, errorMessage, postRollPageUrl } = content);

  const forms: Record<number, ConstructorOfATypedSvelteComponent> = {
    [Forms.NewsletterSignup]: NewsletterSignup,
    [Forms.AskAnExpert]: AskAnExpert,
    [Forms.MerchDiscountCodeSignup]: MerchDiscountCodeSignup,
    [Forms.GoogleAdsLandingPage]: GoogleAdsLandingPage,
    [Forms.EventSignup]: EventSignup,
  };

  let success = false;
  let error = false;
  let loading = false;

  const handleSubmit = async (fields: Record<string, unknown>) => {
    error = false;
    loading = true;

    const response = await fetch('/api/forms', {
      method: 'POST',
      body: JSON.stringify({ fields, formId }),
      headers: {
        'content-type': 'application/json',
      },
    });

    loading = false;
    if (response.ok) {
      success = true;
      if (postRollPageUrl) {
        window.location.assign(postRollPageUrl);
      }
    } else {
      error = true;
    }
  };
</script>

{#if forms[formId]}
  <svelte:component
    this={forms[formId]}
    form={content}
    class={$$props.class}
    {loading}
    {handleSubmit}
  />
{:else}
  {#await getFormMetadata(formId)}
    <p>Loading...</p>
  {:then metadata}
    <DynamicForm
      {metadata}
      {loading}
      on:submit={(event) => {
        event.preventDefault();
        if (event.target instanceof HTMLFormElement) {
          const formData = new FormData(event.target);
          const fields = Object.fromEntries(formData.entries());
          handleSubmit(fields);
        }
      }}
    />
  {:catch}
    <p>Form not found</p>
  {/await}
{/if}

{#if success}
  <p class="font-mono text-sm font-md">{successMessage}</p>
{/if}

{#if error}
  <p class="font-mono text-sm font-md text-red-200">{errorMessage}</p>
{/if}
