<script lang="ts">
  import { twMerge } from 'tailwind-merge';

  import Button from '../button/button.svelte';
  import Checkbox from '@temporalio/ui/holocene/checkbox.svelte';
  import Icon from '../icon/icon.svelte';
  import Input from '@temporalio/ui/holocene/input/input.svelte';
  import Option from '@temporalio/ui/holocene/select/option.svelte';
  import Select from '@temporalio/ui/holocene/select/select.svelte';
  import Text from '../text/text.svelte';

  import {
    isMarketoCheckbox,
    isMarketoHidden,
    isMarketoSelect,
    isMarketoText,
    isMarketoTextInput,
    type MarketFormMetadata,
  } from './marketo-form-metadata';

  import { formatLabel } from './format-label';
  import { stripHtml } from 'string-strip-html';

  export let metadata: MarketFormMetadata;
  export let loading = false;

  let classes = '';
  export { classes as class };
</script>

<div class={twMerge('container', classes)}>
  <form class="flex flex-col gap-4" on:submit>
    {#each metadata.rows.flat() as field, Id}
      {#if isMarketoTextInput(field)}
        <Input
          type={field.DataType === 'email' ? 'email' : 'text'}
          id={String(Id)}
          label={formatLabel(field.InputLabel)}
          name={field.Name}
          required={field.IsRequired}
          value={field.InputInitialValue}
        />
      {/if}
      {#if isMarketoCheckbox(field)}
        <Checkbox
          id={String(Id)}
          label={formatLabel(field.InputLabel)}
          name={field.Name}
          required={field.IsRequired}
          value={field.InputInitialValue}
        />
      {/if}
      {#if isMarketoSelect(field)}
        <Select
          id={String(Id)}
          label={formatLabel(field.InputLabel)}
          name={field.Name}
          required={field.IsRequired}
          value={field.PicklistValues.find((v) => v.isDefault)?.value}
        >
          {#each field.PicklistValues as { value, label }}
            <Option {value}>{label}</Option>
          {/each}
        </Select>
      {/if}
      {#if isMarketoText(field)}
        <Text variant="body-16">{stripHtml(field.Htmltext).result}</Text>
      {/if}
      {#if isMarketoHidden(field)}
        <input
          type="hidden"
          name={field.Name}
          value={field.InputInitialValue}
        />
      {/if}
    {/each}
    <div class="flex items-center justify-end">
      <Button type="submit">
        {#if loading}<Icon name="spinner" class="animate-spin" />{/if}
        {metadata.SubmitLabel}
      </Button>
    </div>
  </form>
</div>
