export type MarketFormMetadata = {
  Id: string;
  Vid: string;
  Name: string;
  SubmitLabel: string;
  ResetLabel: string;
  rows: (
    | MarketoTextInput
    | MarketoSelect
    | MarketoCheckbox
    | MarketoText
    | MarketoHidden
  )[];
};

type MarketoDataType =
  | 'string'
  | 'email'
  | 'picklist'
  | 'single_checkbox'
  | 'htmltext'
  | 'hidden'
  | 'profiling';

interface MarketoField {
  Id: number;
  Datatype: MarketoDataType;
  Name: string;
  InputLabel: string;
  IsRequired: boolean;
  DefaultValue?: string[];
  ValidationMessage?: string;
  InputInitialValue: string;
}

interface MarketoTextInput extends MarketoField {
  DataType: 'string' | 'email';
  Maxlength: number;
}

interface MarketoSelect extends MarketoField {
  Datatype: 'picklist';
  Maxlength?: number;
  PicklistValues: MarketoOption[];
}

interface MarketoCheckbox extends MarketoField {
  Datatype: 'single_checkbox';

  PicklistValues: MarketoOption[];
}

interface MarketoText extends MarketoField {
  Datatype: 'htmltext';
  Htmltext: string;
}

interface MarketoHidden extends MarketoField {
  Datatype: 'hidden';
  InputSourceSelector: string;
}

interface MarketoOption {
  label: string;
  value: string;
  selected?: boolean;
  isDefault?: boolean;
}

export const isMarketoFormMetadata = (
  form: unknown,
): form is MarketFormMetadata => {
  if (!form) return false;
  const f = form as MarketFormMetadata;
  if (!('rows' in f)) return false;
  if (!f.rows.flat().every(isMarketoField)) return false;
  return true;
};

const isMarketoField = (field: unknown): field is MarketoField => {
  if (!field) return false;
  const f = field as MarketoField;
  if (typeof f.Id !== 'number') return false;
  if (typeof f.Datatype !== 'string') return false;
  return true;
};

export const isMarketoTextInput = (
  field: unknown,
): field is MarketoTextInput => {
  if (!isMarketoField(field)) return false;
  if (field.Datatype !== 'string' && field.Datatype !== 'email') return false;
  return true;
};

export const isMarketoSelect = (field: unknown): field is MarketoSelect => {
  if (!isMarketoField(field)) return false;
  if (field.Datatype !== 'picklist') return false;
  return true;
};

export const isMarketoCheckbox = (field: unknown): field is MarketoCheckbox => {
  if (!isMarketoField(field)) return false;
  if (field.Datatype !== 'single_checkbox') return false;
  return true;
};

export const isMarketoText = (field: unknown): field is MarketoText => {
  if (!isMarketoField(field)) return false;
  if (field.Datatype !== 'htmltext') return false;
  return true;
};

export const isMarketoHidden = (field: unknown): field is MarketoHidden => {
  if (!isMarketoField(field)) return false;
  if (field.Datatype !== 'hidden') return false;
  return true;
};
